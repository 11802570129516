<template>
  <Transition name="modal">
    <div class="modal-mask overflow-auto" @click="$emit('close')">
      <div class="modal-container md:!w-3/4" @click.stop>
        <div class="modal-header" v-if="device">
          <div class="flex justify-between">
            <h2>
              Geräteeigenschaften<span v-if="!editEnabled"> - {{ device.name }}</span>
            </h2>
            <div class="my-auto text-gray-400" id="deviceIdToFlespi" @click.ctrl.exact="openDeviceInFlespi">
              <span>#{{ device.id }}</span>
            </div>
          </div>
          <div v-if="!editEnabled">
            <span
              v-if="device.properties.findIndex((p) => p.property_type_id === devicePropertyManufacturerTpyeId) >= 0"
              >{{
                getFormattedValue(
                  device.properties.find((p) => p.property_type_id === devicePropertyManufacturerTpyeId)
                )
              }}</span
            >
            {{
              getFormattedValue(
                device.properties.find((p) => p.property_type_id === devicePropertyTypeDesignationTpyeId)
              )
            }}
            <span class="ml-1 font-light text-gray-500"> ({{ groupStore.groupById(device.group_id).label }}) </span>
          </div>
        </div>
        <div class="modal-body" v-if="device && editEnabled">
          <div class="mt-6 flex flex-col lg:flex-row lg:space-x-8">
            <DeviceEdit :device="device" ref="saveComponent" />
          </div>
        </div>
        <div class="modal-body" v-else-if="device">
          <div class="mt-6 flex flex-col lg:flex-row lg:space-x-8">
            <div class="w-full lg:w-1/2">
              <DeviceProperties :device="device" />
              <DevicePictureGallery :deviceId="device.id.toString()" class="mt-8" />
            </div>
            <div class="mt-4 flex w-full flex-col justify-between lg:mt-0 lg:w-1/2">
              <div class="m-auto md:w-3/4" v-if="device.group_id && device.group_id < 100">
                <DeviceUtilizationChart :deviceId="device.id" :show-ignition="true" />
              </div>
              <div class="mt-8">
                <div class="lg:px-4">
                  <span class="font-semibold" v-if="device.properties.find((p) => p.property_type_id === 3001)"
                    >Am aktuellen Standort seit:</span
                  >
                  {{ getFormattedValue(device.properties.find((p) => p.property_type_id === 3001)) }}
                </div>
                <div class="flex justify-between py-2 lg:p-4">
                  <div>
                    <div>
                      {{ device.position_streetname
                      }}{{ device.position_streetnumber ? ' ' + device.position_streetnumber : '' }},
                      {{ device.position_zipcode }}
                      {{ device.position_city }}
                    </div>
                    <div class="font-light">
                      ({{ device.position.coordinates[1] }}, {{ device.position.coordinates[0] }})
                    </div>
                  </div>
                  <div class="font-light">letzte Meldung: {{ formatDateTime(new Date(device.updated)) }}</div>
                </div>

                <div class="h-72 w-full border">
                  <MapOnePos
                    :long="device.position.coordinates[1]"
                    :lat="device.position.coordinates[0]"
                    :label="device.name"
                    :group_id="device.group_id"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="editEnabled">
            <Button @click="save">Speichern</Button>
            <Button @click="cancel" class="p-button-outlined">Abbrechen</Button>
          </div>
          <div v-else>
            <Button @click="$emit('close')">Schließen</Button>
            <Button @click="showEdit" class="p-button-outlined">Bearbeiten</Button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import { useDeviceStore } from '@/store/device'
  import { useDeviceGroupStore } from '@/store/deviceGroup'
  import { computed, onMounted, ref, watch } from 'vue'
  import { formatDateTime } from '@/helper/date'
  import { getFormattedValue } from '@/helper/property'
  import { devicePropertyManufacturerTpyeId, devicePropertyTypeDesignationTpyeId } from '@/consts'
  import DevicePictureGallery from '@/components/DevicePictureGallery.vue'
  import DeviceProperties from './DeviceProperties.vue'
  import DeviceEdit from './DeviceEdit.vue'
  import MapOnePos from '@/components/MapOnePos.vue'
  import DeviceUtilizationChart from '@/components/DeviceUtilizationChart.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useToast } from 'primevue/usetoast'

  const emit = defineEmits(['close'])
  const props = defineProps<{
    id: number
  }>()

  onMounted(() => {
    window.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Escape') {
          emit('close')
        }
      },
      { once: true }
    )
  })

  const deviceStore = useDeviceStore()
  const groupStore = useDeviceGroupStore()
  const device = computed(() => deviceStore.getDeviceById(props.id))

  const router = useRouter()
  const route = useRoute()
  const toast = useToast()

  const saveComponent = ref<typeof DeviceEdit | null>(null)

  const editEnabled = ref(route.query.edit === 'true')

  const openDeviceInFlespi = () => {
    window.open('https://flespi.io/#/panel/open/devices/' + device.value?.id)
  }

  watch(
    () => route.query.edit,
    (newValue) => {
      editEnabled.value = newValue === 'true'
    }
  )

  const showEdit = () => {
    editEnabled.value = true
    router.push({ query: { edit: 'true', ...route.query } })
  }
  const save = async () => {
    //TODO: change in store
    if (saveComponent.value) {
      const answer = await saveComponent.value.save()
      console.log('save answer', answer)
      if (answer.error) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: answer.error, life: 5000 })

        return
      } else {
        toast.add({ severity: 'success', summary: 'Erfolg', detail: answer.message, life: 5000 })
        router.back()
        return
      }
    }
  }

  const cancel = () => {
    router.back()
  }
</script>

<style scoped>
  .modal-container {
    max-width: 100% !important;
  }

  h2 {
    font-size: 1.5rem;
  }
</style>
