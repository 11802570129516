<template>
  <Layout>
    <template #sidebar_top>
      <SelectButton
        v-model="selectedDeviceType"
        :options="deviceTypes"
        option-label="label"
        :unselectable="false"
        class="deviceTypeSelect"
      />

      <div class="mt-2 flex flex-row">
        <div class="flex-grow">
          <DeviceTreeSelect v-model="selectedGroup" :deviceGroupType="selectedDeviceType" class="w-full" />
        </div>
        <div
          class="flex h-full w-8 flex-shrink-0 cursor-pointer items-center justify-center"
          @click="sortByDistanceToCenter = !sortByDistanceToCenter"
        >
          <img
            v-if="sortByDistanceToCenter"
            src="@/assets/sort_order_dist.png"
            class="h-6"
            alt="Entfernung zur Kartenmitte"
          />
          <img v-else src="@/assets/sort_order_az.png" class="h-6" />
        </div>
      </div>

      <Dropdown
        v-model="selectedMount"
        :options="mounts"
        option-label="label"
        class="mt-2 w-full"
        v-if="mounts.length > 0"
      />

      <div class="p-inputgroup mt-2">
        <InputText v-model="filterTerm" />
        <Button icon="pi pi-search" />
      </div>

      <div class="mt-2 select-none pl-1 text-sm">
        <input
          id="onlyShowDevicesVisibleOnMap"
          v-model="onlyShowDevicesVisibleOnMap"
          type="checkbox"
          class="cursor-pointer border-gray-300 checked:bg-indigo-900 checked:text-indigo-900 focus:ring-0"
        />
        <label for="onlyShowDevicesVisibleOnMap" class="ml-2 cursor-pointer">aktueller Kartenausschnitt</label>
      </div>
      <div class="mt-2 select-none pl-1 text-sm" v-if="!isSmallScreen && userRights.right_device_release">
        <input
          id="onlyShowDevicesReleased"
          v-model="onlyShowDevicesReleased"
          type="checkbox"
          class="cursor-pointer border-gray-300 checked:bg-indigo-900 checked:text-indigo-900 focus:ring-0"
        />
        <label for="onlyShowDevicesReleased" class="ml-2 cursor-pointer">nur frei gemeldete</label>
      </div>
    </template>
    <template #sidebar>
      <div>
        <div
          v-for="device in devicesForList"
          :id="`device_${device.id}`"
          :ref="(el) => updateDeviceRef(el)"
          :key="device.id"
          class="w-full flex-col even:bg-gray-200"
        >
          <DeviceListItem
            :device="device"
            :is-active="device.id === activeDevice?.id"
            @click="setActiveDevice(device)"
            @close="setActiveDevice(null)"
          >
          </DeviceListItem>
          <DeviceDetailTile v-if="device.id === activeDevice?.id" :device="device"> </DeviceDetailTile>
        </div>
      </div>
    </template>
    <template #sidebar_bottom>
      <div class="flex-col">
        <div class="inline-flex w-full items-center px-2">
          <svg
            class="-ml-1 mr-1.5 h-4 w-4 text-ignition-on-and-movement"
            fill="currentColor"
            stroke-width="1px"
            stroke="black"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          <span class="pl-1 text-sm">Zündung an + Bewegung</span>
        </div>
        <div class="inline-flex w-full items-center px-2">
          <svg
            class="-ml-1 mr-1.5 h-4 w-4 text-ignition-on"
            fill="currentColor"
            stroke-width="1px"
            stroke="black"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          <span class="pl-1 text-sm">Zündung an</span>
        </div>
        <div class="inline-flex w-full items-center px-2">
          <svg
            class="-ml-1 mr-1.5 h-4 w-4 text-ignition-off"
            fill="currentColor"
            stroke-width="1px"
            stroke="black"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          <span class="pl-1 text-sm">Zündung aus</span>
        </div>
      </div>
    </template>
    <template #main>
      <MapContainer
        v-if="featuresLoaded"
        :asset-features="assetFeatures"
        :arrow-features="arrowFeatures"
        :center="center"
        :emit-features-in-extent="onlyShowDevicesVisibleOnMap"
        @features-in-extent="updateFeaturesInExtent"
        @update-center="
          (newCenter) => {
            center = newCenter
          }
        "
        @feature-clicked="selectDeviceByFeature"
      >
      </MapContainer>
      <div v-else>Daten werden geladen ...</div>
      <div id="mobile_filter" v-if="isSmallScreen">
        <SelectButton
          v-model="selectedDeviceType"
          :options="deviceTypes"
          option-label="label"
          :unselectable="false"
          class="deviceTypeSelect"
        />
        <Dropdown v-model="selectedGroup" :options="groups" option-label="label" />
        <Dropdown
          v-model="selectedMount"
          :options="mounts"
          option-label="label"
          class="mt-2 w-full"
          v-if="mounts.length > 0"
        />
      </div>
      <div id="device_list_layer" v-if="mobile_show_list">
        <div id="device_list_outer_wrapper">
          <div id="device_list_header">
            <div id="checkbox_current_map_view">
              <input
                id="onlyShowDevicesVisibleOnMap"
                v-model="onlyShowDevicesVisibleOnMap"
                type="checkbox"
                class="cursor-pointer border-gray-300 checked:bg-indigo-900 checked:text-indigo-900 focus:ring-0"
              />
              <label for="onlyShowDevicesVisibleOnMap">aktueller Kartenausschnitt</label>
            </div>
            <div id="device_list_close" @click="mobile_show_list = false">
              <span class="material-symbols-outlined">close</span>
            </div>
          </div>
          <div id="device_list_inner_wrapper">
            <div
              v-for="device in devicesForList"
              :id="`device_${device.id}`"
              :ref="(el) => updateDeviceRef(el)"
              :key="device.id"
              class="w-full flex-col even:bg-gray-200"
            >
              <DeviceListItem
                :device="device"
                :is-active="device.id === activeDevice?.id"
                @click="setActiveDevice(device)"
                @close="setActiveDevice(null)"
              >
              </DeviceListItem>
              <DeviceDetailTile v-if="device.id === activeDevice?.id" :device="device"> </DeviceDetailTile>
            </div>
          </div>
        </div>
      </div>
      <MobileNavigationWrapper v-if="isSmallScreen">
        <div v-if="mobile_search_open" id="mobile_search">
          <!--annoying bugfix - chrome on android doesn't update v-model on type search immediately & but only hides autocomplete bar on type search -->
          <input
            type="search"
            class="p-inputtext p-component w-full"
            autocomplete="off"
            v-model="filterTerm"
            ref="mobile_input_filterterm"
            @input="(e) => (filterTerm = e.target.value)"
          />
        </div>
        <div class="icon-btn" @click.prevent="signout" v-if="!mobile_search_open">
          <span class="material-symbols-outlined">logout</span>
          Logout
        </div>
        <div
          class="icon-btn"
          @click.prevent="router.push('/devices/create')"
          v-if="!mobile_search_open && userRights.right_devices_create"
        >
          <span class="material-symbols-outlined">add_box</span>
          Gerät
        </div>
        <div
          class="icon-btn"
          :class="{ active: mobile_show_list }"
          @click.prevent="mobile_show_list = true"
          v-if="!mobile_search_open"
        >
          <span class="material-symbols-outlined">list</span>
          Liste
        </div>
        <div
          class="icon-btn"
          :class="{ '!text-secondary': filterTerm.length > 0 }"
          @click.prevent="open_mobile_search"
          v-if="!mobile_search_open"
        >
          <span class="material-symbols-outlined">search</span>
          Suche
        </div>
        <div
          class="icon-btn"
          :class="{ inactive: !mobile_show_list && !mobile_search_open }"
          @click.prevent="close_back()"
        >
          <span class="material-symbols-outlined">chevron_left</span>
          Zurück
        </div>
      </MobileNavigationWrapper>
    </template>
  </Layout>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, onUnmounted, reactive, Ref, ref, watch } from 'vue'
  import DeviceListItem from './DeviceListItem.vue'
  import MapContainer from './MapContainer.vue'
  import DeviceTreeSelect from '@/components/devices/DeviceTreeSelect.vue'
  import DeviceDetailTile from './DeviceDetailTile.vue'
  import MobileNavigationWrapper from './MobileNavigationWrapper.vue'
  import { Feature } from 'ol'
  import Point from 'ol/geom/Point'
  import { Style } from 'ol/style'
  import { getStyleByGroupId, getStyleByGroupIdWithColor, transformToOl, getArrowStyle } from '@/controller/MapHelper'
  import {
    DEVICE_TYPES,
    filterGrouspByType,
    filterDevicesByGroup,
    sortDevicesByDistanceToCenter,
    BLE_TYPE,
    DEVICE_GROUP_ALL,
  } from '@/helper/filterAndSorting'
  import { definitions } from '@/generated/types/database'
  import { SearchIcon } from '@heroicons/vue/solid'
  import { Geometry } from 'ol/geom'
  import { supabase } from '@/supabase'
  import { Device, DeviceWithProperties, GroupWithSupportedProperties } from '@/types/extendDatabase'
  import { devicePropertyMountTypeId } from '@/consts'
  import { findIndexByPropertyTypeId, findPropertyByPropertyTypeId } from '@/helper/property'
  import { useAuthStore } from '@/store/auth'
  import { useUserDeviceStore } from '@/store/userDevice'
  import { useUserSettingsStore } from '@/store/userSettings'
  import { usePropertyStore } from '@/store/property'
  import { useDeviceGroupStore } from '@/store/deviceGroup'
  import { useDeviceStore } from '@/store/device'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      DeviceListItem,
      MapContainer,
      DeviceDetailTile,
      SearchIcon,
      MobileNavigationWrapper,
      DeviceTreeSelect,
    },
    setup() {
      let assetFeatures: Feature<Point>[] = []
      let arrowFeatures: Feature<Point>[] = []
      const selectedDeviceGroupType = ref(DEVICE_TYPES[0])
      const sortByDistanceToCenter = ref(false)
      const onlyShowDevicesVisibleOnMap = ref(false)
      const onlyShowDevicesReleased = ref(false)
      const activeDevice = ref()
      const filterTerm = ref('')
      const deviceRefs2: Ref<Map<string, HTMLDivElement>> = ref(new Map())
      let featuresInExtent: Feature<Geometry>[] = reactive([])
      const mobile_show_list = ref(false)
      const mobile_search_open = ref(false)
      const mobile_input_filterterm = ref(null)
      const userRights = computed(() => useAuthStore().userRights)
      const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)

      const userSettingsStore = useUserSettingsStore()
      const propertyStore = usePropertyStore()
      const deviceStore = useDeviceStore()
      const featuresLoaded = ref(false)

      const router = useRouter()
      const route = useRoute()

      const center = computed<number[]>({
        get() {
          return userSettingsStore.center
        },
        set(value) {
          userSettingsStore.center = value
        },
      })

      let arrowGroupIds: number[] = []
      if (import.meta.env.VITE_USE_DIRECTION_MARKER != undefined) {
        arrowGroupIds = import.meta.env.VITE_USE_DIRECTION_MARKER.split(',').map((id: string) => parseInt(id))
      }
      console.log(arrowGroupIds)

      const groups = computed<GroupWithSupportedProperties[]>(() => {
        return useDeviceGroupStore().groups.filter(filterGrouspByType(selectedDeviceGroupType.value))
      })

      const selectedGroup: Ref<GroupWithSupportedProperties> =
        groups.value.length > 0 ? ref(groups.value[0]) : ref(DEVICE_GROUP_ALL)

      const mounts = computed<definitions['device_property_type_select_value'][]>(() => {
        if (findIndexByPropertyTypeId(selectedGroup.value.supported_properties, devicePropertyMountTypeId) === -1)
          return []

        const mountsToReturn: definitions['device_property_type_select_value'][] = [
          { id: -1, label: 'Alle Schnellwecchsler', property_type_id: devicePropertyMountTypeId },
          { id: -2, label: 'ohne Zuweisung', property_type_id: devicePropertyMountTypeId },
        ]
        mountsToReturn.push(...propertyStore.propertyTypeSelectValueByTypeId(devicePropertyMountTypeId))
        return mountsToReturn
      })

      let selectedMount: Ref<definitions['device_property_type_select_value'] | undefined> = ref(undefined)
      watch(mounts, () => {
        selectedMount.value = mounts.value.find((m) => m.id === -1)
      })

      watch(groups, () => {
        selectedGroup.value = groups.value[0]
      })

      const devices = computed<DeviceWithProperties[]>(() => {
        let devicesToReturn = <DeviceWithProperties[]>(
          deviceStore.active
            .filter(filterDevicesByGroup(selectedGroup.value, selectedDeviceGroupType.value))
            .filter((d: DeviceWithProperties) => d.name.toLowerCase().includes(filterTerm.value.toLowerCase()))
        )
        if (onlyShowDevicesReleased.value) devicesToReturn = devicesToReturn.filter((d) => d.released)

        if (sortByDistanceToCenter.value) devicesToReturn.sort(sortDevicesByDistanceToCenter(center.value))

        if (mounts.value.length > 0 && selectedMount.value != undefined && selectedMount.value.id != -1) {
          if (selectedMount.value.id == -2)
            return devicesToReturn.filter(
              (d) => findPropertyByPropertyTypeId(d.properties, devicePropertyMountTypeId) == undefined
            )
          return devicesToReturn.filter(
            (d) =>
              findPropertyByPropertyTypeId(d.properties, devicePropertyMountTypeId)?.value_select_id ===
              selectedMount.value?.id
          )
        }
        return devicesToReturn
      })

      const devicesForList = computed<DeviceWithProperties[]>(() => {
        return !onlyShowDevicesVisibleOnMap.value
          ? devices.value
          : devices.value.filter((d) => {
              return featuresInExtent.find((f) => f.getId() === d.id)
            })
      })

      const reConnectSubscription = () => {
        deviceStore.subscribeToDeviceChanges()
      }
      window.addEventListener('focus', reConnectSubscription)

      onUnmounted(() => {
        window.removeEventListener('focus', reConnectSubscription)
        deviceStore.unsubscribeFromDeviceChanges()
      })

      onMounted(async () => {
        //map
        const helper = async (n: number): Promise<void> => {
          if (deviceStore.active.length > 10 || n <= 0) {
            return Promise.resolve()
          } else {
            await new Promise((r) => setTimeout(r, 200))
            return await helper(n - 1)
          }
        }
        await helper(50)

        for (let d of deviceStore.active) {
          let f = new Feature({
            geometry: new Point(transformToOl(d.position.coordinates)),
            name: d.name,
          })
          f.setStyle(getStyleByGroupId(d.group_id))
          f.setId(d.id)
          assetFeatures.push(f)
          if (arrowGroupIds.includes(d.group_id)) {
            if (d.updated.toISOString().split('T')[0] != new Date().toISOString().split('T')[0]) continue
            let fArrow = new Feature({
              geometry: new Point(transformToOl(d.position.coordinates)),
            })
            let direction: number = d.position_direction
            fArrow.set('direction', direction)
            fArrow.setStyle(getArrowStyle(direction))
            fArrow.setId(d.id * -1)
            arrowFeatures.push(fArrow)
          }
        }
        deviceStore.subscribeToDeviceChanges()
        updateDevicesShownOnMap()
        featuresLoaded.value = true

        if (route.query.device !== undefined && deviceStore.active.length > 0) {
          const tmpActiveDevice = deviceStore.active.find((d) => d.id === parseInt(route.query.device as string))
          if (tmpActiveDevice) activeDevice.value = tmpActiveDevice
        }

        watch(
          () => route.query.device,
          () => {
            if (!activeDevice.value || activeDevice.value?.id !== parseInt(route.query.device as string)) {
              setActiveDevice(deviceStore.active.find((d) => d.id === parseInt(route.query.device as string)) ?? null)
            }
          }
        )
      })

      watch(activeDevice, (newDevice, oldDevice) => {
        if (oldDevice) {
          assetFeatures.find((f) => f.getId() === oldDevice.id)?.setStyle(getStyleByGroupId(oldDevice.group_id))
        }

        if (newDevice) {
          const style = getStyleByGroupIdWithColor(newDevice.group_id, [255, 0, 0, 1])
          style.setZIndex(100)
          assetFeatures.find((f) => f.getId() === newDevice.id)?.setStyle(style)
          if (isSmallScreen.value) {
            mobile_show_list.value = true
            deviceRefs2.value.get(`device_${newDevice.id}`)?.scrollIntoView()
          }
        }
      })

      watch(selectedDeviceGroupType, (_new, _old) => updateDevicesShownOnMap())
      watch(selectedGroup, (_new, _old) => updateDevicesShownOnMap())
      watch(filterTerm, (_new, _old) => updateDevicesShownOnMap())

      const updateDevicesShownOnMap = () => {
        if (devices.value) {
          for (let f of assetFeatures) {
            let d: Device = devices.value.filter((d) => d.id === f.getId())[0]
            if (d) {
              if (activeDevice.value && activeDevice.value.id === d.id) {
                const style = getStyleByGroupIdWithColor(d.group_id, [255, 0, 0, 1])
                style.setZIndex(100)
                f.setStyle(style)
              } else {
                f.setStyle(getStyleByGroupId(d.group_id))
              }
            } else {
              f.setStyle(new Style({}))
            }
          }
        }

        if (selectedDeviceGroupType.value.id != BLE_TYPE && arrowGroupIds.includes(selectedGroup.value.id)) {
          for (let f of arrowFeatures) {
            f.setStyle(getArrowStyle(f.get('direction')))
          }
        } else {
          for (let f of arrowFeatures) {
            f.setStyle(new Style({}))
          }
        }
      }

      const updateFeaturesInExtent = (features: Feature<Geometry>[]) => {
        featuresInExtent.splice(0, featuresInExtent.length)
        featuresInExtent.push(...features)
      }

      watch(onlyShowDevicesVisibleOnMap, (newState, _old) => {
        if (newState === false) featuresInExtent.slice(0, featuresInExtent.length)
      })

      const selectDeviceByFeature = async (feature: Feature<Point>) => {
        if (isSmallScreen.value) {
          mobile_show_list.value = true
          await new Promise((r) => setTimeout(r, 100))
        }
        const deviceId = feature.getId()
        const device: Device = deviceStore.active.filter((d: Device) => d.id === deviceId)[0]
        activeDevice.value = device
        deviceRefs2.value.get(`device_${device.id}`)?.scrollIntoView()
      }

      const updateDeviceRef = (el: Element | null) => {
        if (el) {
          deviceRefs2.value.set(el.id, el)
        }
      }

      const setActiveDevice = (device: Device | null) => {
        activeDevice.value = device
        if (!device) {
          const query = { ...route.query }
          delete query.device
          router.push({ query })
        } else {
          router.push({ query: { device: device.id.toString() } })
          center.value = device.position.coordinates
        }
      }

      const assetIndexes: Map<number, number> = new Map()
      function getIndexById(id: number) {
        let index = assetIndexes.get(id)
        if (index === undefined || index === -1) {
          const index = assetFeatures.findIndex((f) => f.getId() === id)
          if (index != -1) assetIndexes.set(id, index)
          return index
        }
        return index
      }

      watch(
        () => deviceStore.updatedDevices.length,
        () => {
          const deviceId = deviceStore.updatedDevices.pop()
          if (!deviceId) return
          const device = deviceStore.getDeviceById(deviceId)
          if (!device?.active) return

          let index = getIndexById(deviceId)
          if (index != -1) {
            let coords = transformToOl(device.position.coordinates)
            assetFeatures[index].getGeometry()?.setCoordinates(coords)
            let arrowFeature = arrowFeatures.find((f) => (f.getId() as number) * -1 === device.id)
            if (arrowFeature) {
              arrowFeature.getGeometry()?.setCoordinates(coords)
              if (selectedDeviceGroupType.value.id != BLE_TYPE && arrowGroupIds.includes(selectedGroup.value.id))
                arrowFeature.setStyle(getArrowStyle(device.position_direction))
            }
          }
        }
      )

      const close_back = () => {
        if (activeDevice.value) {
          activeDevice.value = null
        } else if (mobile_search_open.value) {
          mobile_search_open.value = false
        } else if (mobile_show_list.value) {
          mobile_show_list.value = false
        }
      }

      const open_mobile_search = () => {
        mobile_show_list.value = true
        mobile_search_open.value = true
        setTimeout(() => {
          mobile_input_filterterm.value?.focus()
        }, 50)
      }

      const signout = () => {
        supabase.auth.signOut()
      }
      return {
        updateDeviceRef,
        filterTerm,
        selectedDeviceType: selectedDeviceGroupType,
        deviceTypes: DEVICE_TYPES,
        selectedGroup,
        groups,
        devices,
        devicesForList,
        activeDevice,
        assetFeatures,
        arrowFeatures,
        center,
        sortByDistanceToCenter,
        onlyShowDevicesVisibleOnMap,
        onlyShowDevicesReleased,
        updateFeaturesInExtent,
        selectDeviceByFeature,
        setActiveDevice,
        mounts,
        selectedMount,
        mobile_show_list,
        close_back,
        mobile_search_open,
        open_mobile_search,
        mobile_input_filterterm,
        signout,
        router,
        userRights,
        isSmallScreen,
        featuresLoaded,
      }
    },
  })
</script>

<style lang="scss" scoped>
  #mobile_filter {
    position: fixed;
    left: 4rem;
    right: 1rem;
    top: 0.7rem;
    bottom: 0;
    pointer-events: none;
    z-index: 99;
    > * {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      pointer-events: auto;
      width: 100%;
      margin-top: 0.5rem;
    }
    :first-child {
      margin-top: 0;
    }
  }

  #device_list_layer {
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
  }

  #device_list_outer_wrapper {
    position: absolute;
    height: calc(40% - 60px);
    width: calc(90% - 9px - 9px);
    bottom: 60px;
    display: flex;
    background-color: #fff;
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    #device_list_header {
      font-size: 0.5rem;
      display: flex;
      padding-top: 0.25rem;
      padding-right: 0.5rem;
      padding-bottom: 0.25rem;
      justify-content: space-between;
      width: 100%;
      pointer-events: auto;
      color: #000;
      border-top-left-radius: 0.375rem !important;
      border-top-right-radius: 0.375rem !important;
      border-bottom: 1px solid var(--neutrals-900);
      background-color: var(--neutrals-050);

      #checkbox_current_map_view {
        margin-left: 1rem;
        > input {
          width: 12px;
          height: 12px;
        }
        > label {
          font-size: 0.85rem;
          margin-left: 0.5rem;
        }
      }

      #device_list_close {
        height: 100%;
        > span {
          font-size: 01rem !important;
          vertical-align: middle;
        }
      }
    }

    #device_list_inner_wrapper {
      height: 100%;
      width: 100%;

      overflow-x: scroll;
      pointer-events: auto;
    }
  }

  #mobile_search {
    width: 100%;
    padding-left: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  //select button for gps, both and ble
  :deep(.deviceTypeSelect.p-selectbutton) {
    .p-button {
      padding: 4px;
      width: 33.333333%;
      font-size: 0.9rem;
      border: 0 !important;
      border-radius: 0.25rem !important;
      background-color: var(--primary-color) !important;
      color: #fff !important;
      &:hover {
        color: #fff !important;
        background: var(--secondary-color) !important;
      }
    }

    .p-highlight {
      color: var(--secondary-color) !important;
      font-weight: bold !important;
    }
    width: 100%;
    flex: auto;
    background: var(--primary-color);
    border-radius: 0.25rem;
  }
</style>
